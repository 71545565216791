<template>
  <div>
    <PageHeader fileName="header-15" withTablet withMobile>
      <h3>
        {{ $lang("title") }}
      </h3>
    </PageHeader>

    <section class="py-7">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 d-flex flex-column">
            <h2 class="mb-4" v-html="$lang('header.title')" />
          </div>
          <div class="col-lg-6 offset-lg-1">
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ $lang("header.paragraph1") }}
            </h6>
            <p>
              {{ $lang("header.paragraph2") }}
            </p>

            <p>
              {{ $lang("header.paragraph3") }}
            </p>
          </div>
        </div>
        <div class="row mt-5 justify-content-center gap-y-4">
          <div class="col-sm-9 col-md-4">
            <ImageCaption
              class="img-container-rounded"
              style="aspect-ratio: 1; --size: auto"
              fileName="shared-apartment"
            >
              <h6 class="fw-600">{{ $lang("gallery.photo1") }}</h6>
            </ImageCaption>
          </div>
          <div class="col-sm-9 col-md-4">
            <ImageCaption
              class="img-container-rounded"
              style="aspect-ratio: 1; --size: auto"
              fileName="student-residence"
            >
              <h6 class="fw-600">{{ $lang("gallery.photo2") }}</h6>
            </ImageCaption>
          </div>
          <div class="col-sm-9 col-md-4">
            <ImageCaption
              class="img-container-rounded"
              style="aspect-ratio: 1; --size: auto"
              fileName="hostel"
            >
              <h6 class="fw-600">{{ $lang("gallery.photo3") }}</h6>
            </ImageCaption>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-dark py-7">
      <div class="container">
        <div class="row mb-6" v-for="(s, i) of $lang('sections')" :key="i">
          <div class="col-lg-4">
            <h4 class="text-secondary">{{ s.title }}</h4>
          </div>
          <div class="col-lg-7 offset-lg-1">
            <ConnectedList
              :items="s.items"
              :untilEnd="s.untilEnd"
              theme="secondary"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="py-7 bg-secondary">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <h4 class="mb-5 mb-lg-0 text-dark">
              {{ $lang("footer.title") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card1.title')"
              :to="{ name: 'methodology', hash: '#levels' }"
            >
              <small class="m-0">
                {{ $lang("footer.card1.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card2.title')"
              :to="{ name: 'our_courses' }"
            >
              <small class="m-0">
                {{ $lang("footer.card2.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-3 mb-4 mb-lg-0">
            <CardButton
              :title="$lang('footer.card2.title')"
              :to="{ name: 'faqs' }"
            >
              <small class="m-0">
                {{ $lang("footer.card3.body") }}
              </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-14" theme="secondary">
      {{ $lang("contact_form.location") }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import ImageCaption from "@/components/ImageCaption.vue";
import ConnectedList from "@/components/ConnectedList.vue";
import CardButton from "@/components/CardButton.vue";

export default {
  components: {
    CardButton,
    PageHeader,
    ConnectedList,
    ImageCaption,
    ContactFormSection,
  },
};
</script>

<style></style>
